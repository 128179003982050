//  Import Routes
// import { ProfileRoutes } from "./modules/profile/routes";
import Vue from "vue";
import VueRouter from "vue-router";
import VueCookies from "vue-cookies";
import { validateAndSetAuth } from "@/modules/auth/service";
import { FormAdmin } from "@/modules/form-admin/routes";
// import { RolesAndPermissions } from "@/modules/roles-and-permissions/routes"
import { LightRayRoutes } from "@shared/light-ray/routes"
import { License } from "@/modules/license/routes"
import { checkPermission ,getParamsFromUrl} from "@shared/utils/functions"


Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "dashboard.view",
        component: () => import(/* webpackChunkName: "dashboard" */ "@/pages/dashboard"),
        redirect: { name: "Dashboard - Page 1" },
        children: [
            {
                path: "case-dashboard",
                name: "Dashboard - Page 1",
                component: () => import(/* webpackChunkName: "dashboard-page1" */ "@/pages/dashboard/pages/case-dashboard"),
                // component: () => import(/* webpackChunkName: "dashboard-page1" */ "@/pages/bi-dashboard/bi-dashboard"),
                meta: {
                    title: "",
                },
            },
            {
                path: "default-dashboard",
                name: "Dashboard - Page 2",
                component: () => import(/* webpackChunkName: "dashboard-page1" */ "@/pages/dashboard/pages/case-dashboard"),
                meta: {
                    breadcrumb: [{ name: "dashboard List" }],
                    title: "",
                },
            },

            {
                path: "/bi-dashboard-view/:id",
                name: "Bi-Dashboard-View",
                component: () => import(/* webpackChunkName: "dashboard-page1" */ "@/pages/bi-dashboard/bi-dashboard-view"),
                meta: {
                    title: "Analyst Work Bench",
                },
            },
            // {
            //     path: "/bi-dashboard-edit/:id",
            //     name: "Bi-Dashboard-Edit",
            //     component: () => import(/* webpackChunkName: "dashboard-page1" */ "@/pages/bi-dashboard/bi-dashboard-edit"),
            //     meta: {
            //         title: "Analyst Work Bench",
            //     },
            // },
           
            // {
            //     path: "/bi-data-source",
            //     name: "Bi-Data-Source",
            //     component: () => import(/* webpackChunkName: "dashboard-page1" */ "@/pages/bi-dashboard/bi-data-source"),
            //     meta: {
            //         title: "Analyst Work Bench",
            //     },
            // },
            {
                path:"case/landing/:case_id",
                name: "lightray-redirect",
                component: () => import(/* webpackChunkName: "login" */ "../pages/lightray-redirection"),
            },
            {
                // to be changed to be something more logical according to route
                path: "email-admin",
                name: "email-admin",
                meta: {
                    title: "Email Admin",
                },
                component: () => import(/* webpackChunkName: "email-admin" */ "../pages/email-admin"),
            },
            {
                // to be changed to be something more logical according to route
                path: "default-template",
                name: "default-template",
                meta: {
                    breadcrumb: [{ name: "Admin" }, { name: "Emails" }, {name: "Default Templates/Sequences"}],
                    title: "Default Email",
                },
                component: () => import(/* webpackChunkName: "email-admin" */ "../pages/default-template"),
            },
            {
                // to be changed to be something more logical according to route
                path: "2",
                name: "Dashboard - Page 2",
                component: () => import(/* webpackChunkName: "dashboard-page2" */ "../pages/dashboard/pages/page2"),
            },
            {
                name: "Fields",
                path: "/field-admin",
                component: () => import(/* webpackChunkName: "field-admin" */ "../pages/field-table/field-tab"),
                meta: {
                    breadcrumb: [{ name: "Admin" }, { name: "Field List" }],
                    title: "Field Admin",
                },
            },
            {
                name: "Field Setting",
                path: "/field-settings/:id/:action",
                component: () => import(/* webpackChunkName: "field-settings" */ "../pages/field-table/field-admin-view"),
                meta: {
                    breadcrumb: [{ name: "Admin" }, { name: "Field List" ,clickable:true , goTo:'/field-admin?tab=fieldadmin' }, { name: "Field Info" }],
                    title: "Field Details",
                },
                props: true,
            },
            {
                name: "Create package",
                path: "/create-package",
                component: () => import(/* webpackChunkName: "create-package" */ "../pages/create-package"),
                props: true,
                meta: {
                    breadcrumb: [{ name: "packages" }, { name: "create" }],
                    title: "Create Package",
                },
            },
            {
                name: "Add Field",
                path: "/add-field/:field?",
                component: () => import(/* webpackChunkName: "add-field" */ "../pages/field-table/field-admin-view"),
                meta: {
                    breadcrumb: [{ name: "Admin" }, { name: "Field List" }, { name: "Create Field" }],
                    title: "Add Field",
                },
                props: true,
            },
            {
                name: "check Admin",
                path: "/check-admin",
                component: () => import(/* webpackChunkName: "check-admin" */ "../pages/check-admin"),
                meta: {
                    breadcrumb: [{ name: "Admin" }, { name: "Check List" }],
                    title: "Check Admin",
                },
            },
            {
                name: "check admin details",
                path: "/check-admin/view/:id/setting/:action",
                component: () => import(/* webpackChunkName: "company-admin-view-settings" */ "../pages/check-admin/check-dragable-section"),
                meta: {
                    breadcrumb: [{ name: "Admin" }, { name: "Check List" ,clickable:true , goTo:'/check-admin'}, { name: "Check Info" }, { name: "Check Edit" }],
                    title: "Check Details",
                },
                // component: () => import("../pages/check-admin/check-dragable-section-dragable-field"),
            },
            {
                name: "check View",
                path: "/check-admin/view/:id",
                component: () => import(/* webpackChunkName: "check-admin-view" */ "../pages/check-admin/check-view"),
                meta: {
                    breadcrumb: [{ name: "Admin" }, { name: "Check List" }, { name: "Check Info" }],
                    title: "Check Fields",
                },
            },
            {
                name: "Users",
                path: "/users",
                component: () => import(/* webpackChunkName: "users" */ "../pages/user-details"),
                meta: {
                    breadcrumb: [{ name: "Admin" }, { name: "Users" }],
                    title: "Users",
                },
            },
            {
                name: "User Setting",
                path: "/users/:id",
                component: () => import(/* webpackChunkName: "user-settings" */ "../pages/user-details/userTableDataView"),
                props: true,
                meta: {
                    breadcrumb: [{ name: "Admin" }, { name: "Users" , giveAccess: true , goTo:'/users'}, { name: "User Details" }],
                    title: "User Details",
                },
            },
            {
                name: "Group Details",
                path: "/groups",
                meta: {
                    breadcrumb: [{ name: "Admin" }, { name: "Groups" }],
                    title: "Group Details",
                },
                component: () => import(/* webpackChunkName: "group-details" */ "../pages/group-details"),
            },
            {
                name: "Group Setting",
                path: "/group-settings/:id",
                component: () => import(/* webpackChunkName: "group-settings" */ "../pages/group-details/groupTableDataView"),
                props: true,
                meta: {
                    breadcrumb: [{ name: "Admin" }, { name: "Groups" , giveAccess: true , goTo:'/groups' }, { name: "Group Details" }],
                    title: "Group Details",
                },
            },
            {
                name: "Profile Container",
                path: "/case/:caseid/profile-view",
                component: () => import(/* webpackChunkName: "profile-view" */ "../pages/profile-view"),
                meta: {
                    showCaseInfo: true,
                },
            },
            {
                name: "Screening Package",
                path: "/packages",
                component: () => import(/* webpackChunkName: "screening-packages" */ "../pages/tenant-packages"),
                meta: {
                    breadcrumb: [{ name: "Admin" }, { name: "Package List" }],
                    title: "Packages",
                },
            },
            {
                name: "Create Case Form",
                path: "/create-case-form/:id",
                component: () => import(/* webpackChunkName: "case-creation-form" */ "../pages/create-case/createCaseForm"),
            },

            {
                name: "Create Case",
                path: "/create-case",
                component: () => import(/* webpackChunkName: "create-case" */ "../pages/create-case"),
                meta: {
                    title: "Case Creation"
                }
            },
            {
                name: "pending-activity",
                path: "/activity",
                component: () => import(/* webpackChunkName: "pending-activity" */ "../pages/activity-history-notification"),
                meta: {
                    title: "Pending Activities",
                }
            },

            {
                name: "Clients List",
                path: "/clients-list",
                component: () => import(/* webpackChunkName: "client-list" */ "@/pages/tenant-client/client-list"),
                meta: {
                    breadcrumb: [{ name: "Admin" }, { name: "Client List" }],
                    title: "Clients",
                },
            },
            {
                name:"salesOrdersList",
                path: "/sales-orders",
                component: () => import(/* webpackChunkName: "sales-orders" */ "@/pages/sales-orders/orders-list"),
                meta: {
                    breadcrumb: [{name: "Tenant Admin"}, {name:"Sale Orders"}],
                    title: "Sales"
                }
            },
            {
                name:"salesOrderInfo",
                path: "/order-info/:id?/:client_id?",
                component: () => import(/* webpackChunkName: "sales-orders" */ "@/pages/sales-orders/create-order/create-orders"),
                meta: {
                    breadcrumb: [{name: "Tenant Admin"}, {name:"Sale Orders"}],
                    title: "Sales"
                }
            },
            {
                name: "client",
                path: "/clients",
                component: () => import(/* webpackChunkName: "create-client" */ "@/pages/tenant-client/create-client"),
                meta: {
                    breadcrumb: [{ name: "Admin" }, { name: "Client List" }, { name: "Create Client" }],
                    title: "Create Client",
                },
                children: [
                    {
                        name: "create-client",
                        path: "create-client/:tab?",
                        meta: {
                            breadcrumb: [{ name: "Admin" }, { name: "Create Client" }],
                            customCrumbs: [],
                            title: "Client",
                        },
                    },
                    {
                        name: "client-details",
                        path: ":clientId/:tab?",
                        meta: {
                            breadcrumb: [{ name: "Admin" }, { name: "Client List", giveAccess: true, goTo: '/clients-list' }, { name: "Client Info" }],
                            customCrumbs: [],
                            title: "Client",
                        },
                    },
                    
                ]
            },

            {
                name: "Edit Client",
                path: "/client/:id",
                component: () => import(/* webpackChunkName: "edit-client" */ "../pages/create-client"),
                meta: {
                    breadcrumb: [{ name: "Admin" }, { name: "Client List" }, { name: "Client Info" }],
                    title: "Client Details",
                },
            },

            {
                name: "Screening Package View",
                path: "/packages/:packageName/:action",
                component: () => import(/* webpackChunkName: "screening-packages-view" */ "../pages/tenant-packages/package-details"),
                meta: {
                    breadcrumb: [{ name: "Admin" }, { name: "Package List" }, { name: "Package Info" }],
                    title: "Package Details",
                },
                children: [
                    {
                        path: ":packagetool?",
                        name: "Package Tools",
                        meta: {
                            breadcrumb: [{ name: "Admin" }, { name: "Package List" , clickable:true , goTo:'/packages'}, { name: "Package Info" }],
                            customCrumbs: [],
                            title: "Package Details",
                        },
                    },
                ],
            },
            {
                name: "File Browser",
                path: "/file-browser",
                component: () => import(/* webpackChunkName: "file-browser" */ "@/pages/file-browser"),
            },
            {
                name: "Custom Domain",
                path: "/custom-domain",
                component: () => import(/* webpackChunkName: "file-browser" */ "@/pages/custom-domain"),
                meta: {
                    title: "Custom Domain",
                }
            },
            {
                name: "Configurations",
                path: "/configurations",
                component: () => import(/* webpackChunkName: "file-browser" */ "@/pages/configurations"),
                meta: {
                    breadcrumb: [{ name: "Settings" }, { name: "Configurations" }],
                    title: "Configurations",
                }
            },
           
            // {
            //     name: "Form-Admin",
            //     path: "/form-admin",
            //     component: () => import(/* webpackChunkName: "idcheck-confirmation" */ "../modules/form-admin"),
            //     meta: {
            //         breadcrumb: [{ name: "Admin" }, { name: "Forms" }],
            //         customCrumbs: [],
            //         title: "Forms",
            //     },
            // },
            {
                name: "block-admin-details",
                path: "/block-admin/:id/:action",
                component: () => import(/* webpackChunkName: "idcheck-confirmation" */ "../pages/block-admin/block-admin-details"),
                meta: {
                    title: "Block Admin",
                    breadcrumb: [{ name: "Admin" }, { name: "Block List" , clickable:true , goTo:'/field-admin?tab=blockadmin' }, { name: "Block Details" }],
                },
            },
            {
                name: "App Settings: Logo And Theme",
                path: "/logo-and-theme",
                component: () => import(/* webpackChunkName: "app-settings" */ "../pages/theme-admin"),
            },
            {
                name: "unauthenticated",
                path: "/unauthenticated",
                component: () => import(/* webpackChunkName: "not-found" */ "@shared/components/unauthenticated")
            },
            {
                path: "/credit-packs",
                component: () => import(/* webpackChunkName: "roles and permissions" */ "@/pages/credit-packs/index.vue"),
                meta: {
                    breadcrumb: [{ name: "Tenant Admin" }, { name: "Credit Packs" }],
                    title: "Credit Packs",
                },
            },
            {
                name: "Allocate Credits",
                path: "/allocate-credits",
                component: () => import(/* webpackChunkName: "user-details" */ "../pages/credit-packs/allocate-pack"),
                meta: {
                    title: "Credit Admin",
                    breadcrumb:[{ name: "Tenant Admin" }, { name: "Credit Pack" }, { name: "Allocate Credit Pack" }],
                },
            },
            {
                name: "allotedcredit",
                path: "/allocate-credits/:id",
                component: () => import(/* webpackChunkName: "user-details" */ "../pages/credit-packs/update-allotted-pack"),
                meta: {
                    title: "Credit Admin",
                    breadcrumb:[{ name: "Tenant Admin" }, { name: "Credit Pack" }, { name: "Modify Allocate Credit Pack" }],
                },
            },
            {
                name: "Pack Details",
                path: "/pack-details/:id",
                component: () => import(/* webpackChunkName: "fpack-details" */ "@/pages/credit-packs/pack-details/index.vue"),
                meta: {
                    breadcrumb: [{ name: "Tenant Admin" }, { name: "Credit Packs" }, { name: "Pack Details" }],
                    title: "Pack Details",
                },
                props: true,
            },
            {
                name: "inventoryDetails",
                path: "/inventory-details/:id",
                component: () => import(/* webpackChunkName: "user-details" */ "@/pages/credit-packs/inventory-details"),
                meta: {
                    title: "Credit Admin",
                    breadcrumb:[{ name: "Tenant Admin" }, { name: "Credit Pack" }, { name: "Inventory Details" }],
                },
            },
            {
                name: "Purchase Order",
                path: "/purchase-order",
                component: () => import(/* webpackChunkName: "app-settings" */ "../pages/purchase-order/orders-list.vue"),
                meta: {
                    breadcrumb: [{ name: "Purchase" }, { name: "Purchase Orders" }],
                }
            },
            {
                name: "purchseOrderInfo",
                path: '/orders-info/:id?',
                component: () => import(/* webpackChunkName: "createCurrency" */ "../pages/purchase-order/create-order/create-orders.vue"),
                meta: {
                    breadcrumb: [{ name: "Purchase" }, { name: "Purchase Orders" }, {name : "Create Order"}],
                }
            },
            
            {
                path: "/manage-tax",
                component: () => import(/* webpackChunkName: "roles and permissions" */ "@/pages/manage-tax/index.vue"),
                meta: {
                    breadcrumb: [{ name: "Tenant Admin" }, { name: "Manage Tax" }, { name: "Manage Tax List" }],
                    title: "Tax Admin",
                    customClass: "Prerana"
                },
            },
            {
                name: "manageTaxInfo",
                path: '/manage-tax-info/:id?',
                component: () => import(/* webpackChunkName: "taxList" */ "../pages/manage-tax/manage-tax-info"),
                meta: {
                    breadcrumb: [{ name: "Tenant Admin" }, { name: "Manage Tax" }],
                }
            },
            // {
            //     path: "/license",
            //     component: () => import(/* webpackChunkName: "roles and permissions" */ "@/pages/license/index.vue"),
            //     meta: {
            //         breadcrumb: [{ name: "Tenant Admin" }, { name: "License" }, { name: "License List" }],
            //         title: "License",
            //     },
            // },
            // {
            //     name: "license-details",
            //     path: '/license-details/:id?',
            //     component: () => import(/* webpackChunkName: "taxList" */ "../pages/license/license-details"),
            //     meta: {
            //         breadcrumb: [{ name: "Tenant Admin" }, { name: "License" }, { name: "License Details" }],
            //     }
            // },
            
            // Module Routes
            ...FormAdmin,
            ...License,
            // ...RolesAndPermissions,
            {
                path: "/roles-and-permissions",
                component: () => import(/* webpackChunkName: "roles and permissions" */ "@/pages/roles-and-permissions/index.vue"),
                meta: {
                    breadcrumb: [{ name: "Admin" }, { name: "Roles and Permissions" }],
                    title: "Roles and Permissions",
                },
                children: [
                    {
                        path: "/",
                        name: "roles-and-permissions-list",
                        component: () => import(/* webpackChunkName: "roles and permissions list" */ "@/pages/roles-and-permissions/roles-and-permissions-list"),
                        meta: {
                            breadcrumb: [{ name: "Admin" }, { name: "Roles and Permissions" }],
                            title: "Roles and Permissions",
                        },
                    },
                    {
                        path: "details/:id",
                        name: "roles-and-permissions-details",
                        component: () => import(/* webpackChunkName: "roles and permissions details" */ "@/pages/roles-and-permissions/roles-and-permissions-details"),
                        meta: {
                            breadcrumb: [{ name: "Admin" }, { name: "Roles and Permissions" }, {name: 'details'}],
                            title: "Roles and Permissions",
                        },
                    },
                ],
            },
            ...LightRayRoutes,
            
        ],
    },
    // {
    //     path: "/login",
    //     name: "Login",
    //     component: () => import(/* webpackChunkName: "login" */ "@/modules/auth"),
    // },
    {
        path: "/login",
    },
    {
        path: "/otp-login",
        name: "otp-login",
        component: () => import(/* webpackChunkName: "dashboard-page1" */ "@/pages/login/otpScreen"),
        meta: {
            title: "otp-login",
        },
    },
    {
        path: "/update-password",
        name: "update-password",
        component: () => import(/* webpackChunkName: "dashboard-page1" */ "@/pages/login/passwordUpdate"),
        meta: {
            title: "update-password",
        },
    },
    {
        path: "/forgot-password",
        name: "forgot-password",
        component: () => import(/* webpackChunkName: "dashboard-page1" */ "@/pages/login/forgotPasswordPage"),
        meta: {
            title: "forgot-password",
        },
    },
    // {
    //     path: "/tools",
    //     name: "Tools",
    //     meta: {
    //         title: "new-login",
    //     },
    // },

    {
        path: "/otp-login",
        name: "otp-login",
        component: () => import(/* webpackChunkName: "dashboard-page1" */ "@/pages/login/otpScreen"),
        meta: {
            title: "otp-login",
        },
    },
    {
        path: "/update-password",
        name: "update-password",
        component: () => import(/* webpackChunkName: "dashboard-page1" */ "@/pages/login/passwordUpdate"),
        meta: {
            title: "update-password",
        },
    },
    {
        path: "/forgot-password",
        name: "forgot-password",
        component: () => import(/* webpackChunkName: "dashboard-page1" */ "@/pages/login/forgotPasswordPage"),
        meta: {
            title: "forgot-password",
        },
    },
    {
        path: "/login",
        name: "Login",
        component: () => import(/* webpackChunkName: "login" */ "@/pages/login"),
    },
    // {
    //     path: "/new-login",
    //     name: "new-login",
    //     component: () => import(/* webpackChunkName: "dashboard-page1" */ "@/pages/login"),
    //     meta: {
    //         title: "new-login",
    //     },
    // },

    {
        path: "/otp-login",
        name: "otp-login",
        component: () => import(/* webpackChunkName: "dashboard-page1" */ "@/pages/login/otpScreen"),
        meta: {
            title: "otp-login",
        },
    },
    {
        path: "/update-password",
        name: "update-password",
        component: () => import(/* webpackChunkName: "dashboard-page1" */ "@/pages/login/passwordUpdate"),
        meta: {
            title: "update-password",
        },
    },
    {
        path: "/forgot-password",
        name: "forgot-password",
        component: () => import(/* webpackChunkName: "dashboard-page1" */ "@/pages/login/forgotPasswordPage"),
        meta: {
            title: "forgot-password",
        },
    },
    {
        name: "Email Template Admin",
        path: "/email-template-admin",
        component: () => import(/* webpackChunkName: "email-template-admin" */ "../pages/email-template"),
        meta: {
            title: "Email Admin",
        },
    },
    {
        name: "Email Template",
        path: "/email-template-admin/:folder",
        component: () => import(/* webpackChunkName: "email-template-admin-view" */ "../pages/email-template/email-lists"),
    },
    {
        name: "LoginCallback",
        path: "/login-success",
        component: () => import(/* webpackChunkName: "login-callback" */ "../modules/auth/login-success"),
        props: true,
    },
    {
        name: "Form Builder",
        path: "/form/preview/:package",
        component: () => import(/* webpackChunkName: "form-builder" */ "../pages/form-builder"),
    },
    {
        name: "SC Journey",
        path: "/sc-journey",
        component: () => import(/* webpackChunkName: "sc-journey" */ "../pages/sc-journey"),
    },
    {
        name: "not-found",
        path: "*",
        component: () => import(/* webpackChunkName: "not-found" */ "../pages/not-found"),
    },
  
    {
        path: "/dependant-fields",
        component: () => import(/* webpackChunkName: "dependant-fields" */ "../components/dependant-fields"),
    },
    {
        path: "/idcheck-confirmation",
        name: "IDCheckConfirmation",
        component: () => import(/* webpackChunkName: "idcheck-confirmation" */ "@shared/idcheck-confirmation"),
    },
    {
        name: "AdditionalForm",
        path: "/additional-form/preview/:form_id",
        component: () => import(/* webpackChunkName: "additional-forms" */ "../pages/additional-forms"),
    },

];

const router = new VueRouter({
    // base:process.env.VUE_APP_BASE_URL_TENANT,
    routes,
    mode: "history",
});

router.onError((error) => {
    if (/loading chunk \d* failed./i.test(error.message)) {
        window.location.reload();
    }
});

/// AUTH MIDDLEWARE

router.beforeEach(async (to, from, next) => {
    checkPermission;
    // GET AUTH TOKEN
    const access_token = VueCookies.get("token");
    const refresh_token = VueCookies.get("refresh_token");
    const params = getParamsFromUrl();
    if (access_token && from.name === null) {
        // if user directly came to any specific url
        await validateAndSetAuth(access_token, refresh_token);
        // interceptResponse();
        next();
    }
    if (!access_token) {

        if (to.name === "LoginCallback" || to.name === "Login" || to.name === 'unauthenticated' || to.name === 'forgot-password' || to.name === 'update-password' || to.name === 'otp-login' || to.name === 'new-user-login') {
            next();
        } else {
            // If not logged in
            // Trying to navigate to another page, send to login but keep next-url.
            // VueCookies.set("next-url", to.fullPath);
            next({ name: "Login" ,params: params});
        }
        next();
    } else {
        if (to.name === "LoginCallback" || to.name === "Login" || to.name === "Dashboard.view") {
            // If logged in
            // Trying to open the Login page, you be crazy!
            // interceptResponse();
            next({ name: "dashboard.view" });
        } else {
            // All systems good to go!
            // interceptResponse();
            next();
        }
    }
});
export default router;