import axios from "@/axios"
import { default as rawAxios } from "axios";
import {getBaseUrlDelviumBackend} from "@shared/utils/functions"
import bHelper from "@/boldbi"

import store from "@/store"
import JWT from "jsonwebtoken";

async function logoutFromSaas() {
    const url = `user-auth/logout`;
    try {
        const { data } = await axios.post(url);
        bHelper.biLogout(data)
        return data.logout_redirect_uri
    } catch (error) {
        throw "LogoutFailed";
    }
    // return true;
}


function decodeToken(token) {
    const decoded = JWT.decode(token);
    return decoded;
}

/*

@param {sting} token - access token.
@returns {bool} -
*/
async function validateTokenLocal(token) {
    if (store.getters.getUserPermissions && !store.getters.getUserPermissions.length && token) {
        await store.dispatch("fetchPermissions")
    }
    // let decodedToken;
    try {
        return decodeToken(token);
    } catch (error) {
        console.warn("decode jwt local error ->", error)
        return false;
    }
}


async function validateAndSetAuth(access_token, refresh_token) {
    let decoded = validateTokenLocal(access_token)
    try {
        if (decoded) {
            const expiry = decoded.exp * 1000
            const now = Date.now()
            if (now >= expiry) {
                // token is expired 
                const new_token = await fetchRefreshToken(access_token, refresh_token)
                store.dispatch("setNewUserToken", new_token)
                return false
            }
            return true
        }
        else {
            store.dispatch("logoutUser");
            return false
        }

    } catch (error) {
        console.log(error.message || 'validate token locally failed error')
    }
    // if (validateTokenLocal(token)) return true
    // let validatedToken = await validateTokenRemote(token);
    // if (validatedToken?.error) {
    //     if (validatedToken.error.type === "invalid") {
    //         store.dispatch("logoutUser");
    //     }
    //     if (validatedToken.error.type === "expired") {
    //         try {
    //             const _token = await fetchRefreshToken(token);
    //             let decodedToken = decodeToken(_token);
    //             store.dispatch("setLoginSession", { decodedToken, token: _token });
    //         } catch (error) {
    //             // if refresh token throws an error logout the user
    //             console.error(error);
    //             store.dispatch("logoutUser");
    //         }
    //     }
    // } else {
    //     // token is valid
    //     const tokenVerifiedByLocalCert = validateTokenLocal(token);
    //     if (tokenVerifiedByLocalCert) {
    //         let decodedToken = decodeToken(token);
    //         store.dispatch("setLoginSession", { decodedToken, token: token });
    //         // yay!
    //     } else {
    //         // oops !
    //         store.dispatch("logoutUser");
    //     }
    // }
}
async function fetchRefreshToken(access_token, refresh_token) {
    const base = getBaseUrlDelviumBackend()
    const url = `${base}/user-auth/access-from-refresh`;
    // const url = `/api/v1/user-auth/token`;
    try {
        // let config = {
        //     headers: {
        //         Authorization: 'Bearer ' + token
        //     }
        // }
        let response = await rawAxios.post(url,{"access_token": access_token, "refresh_token": refresh_token});
        return response.data.data.access_token;
    } catch (error) {
        console.error(error);
        throw new Error("Refresh token failed from server!");
    }
}

export { logoutFromSaas, validateAndSetAuth, validateTokenLocal, fetchRefreshToken };